import React, { useEffect, useContext, useMemo, useRef } from 'react';
import {
  NavContext,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';

import UserContext from '../components/user/Context';
import Page from '../components/core/Page';
import Brand from '../components/core/Brand';
import { Link } from '../components/core/router';
import useAnalytics from '../hooks/useAnalytics';
import SplitPanels from '../components/core/panels/SplitPanels';
import { DEFAULT_ONBOARDING_COVER_PHOTO_URL } from '../asset-config';

const Home = () => {
  useAnalytics('Landing');
  const { currentPath, navigate } = useContext(NavContext);
  const { isAuthenticated } = useContext(UserContext);
  const isMounted = useRef(true);

  const home = useMemo(() => {
    return isAuthenticated ? '/feed' : '/auth';
  }, [isAuthenticated]);

  useIonViewWillEnter(() => {
    isMounted.current = true;
  }, []);

  useIonViewWillLeave(() => {
    isMounted.current = false;
  });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if (currentPath === '/' && isAuthenticated)
      navigate(home, 'none', 'replace');
  }, [currentPath, navigate, home, isAuthenticated]);

  // prevent component from flashing since it will just be redirected right away
  if (isAuthenticated) return null;

  // welcome / landing page for non-logged-in users
  return (
    <Page
      layout="basic"
      className="home-page"
      hideHeader
      mobileHeader={() => null}
    >
      <SplitPanels headerImg={DEFAULT_ONBOARDING_COVER_PHOTO_URL}>
        <div className="pt-4 xl:pt-0">
          <Brand variant="logo" color="white" size="6xl" />
          <div className="text-white text-center my-6 3xl:my-8">
            <div className="h0 leading-tight font-extrabold block">
              Access top-tier developers.
            </div>
            <div className="h0 leading-tight font-extrabold block mt-6 3xl:mt-8">
              <br />
            </div>
          </div>
          <div className="text-white text-center text-2xl">
            Finding great talent has never been easier.
          </div>
          <div className="mt-12 3xl:mt-16 text-center">
            <div>
              <Link
                to="/get-started"
                className="btn btn--sm btn--primary btn--rev btn--full md:w-auto mb-4"
              >
                Get started
              </Link>
            </div>
            <Link
              to="/login"
              className="btn btn--sm btn--primary btn--rev btn--full md:w-auto mb-4"
            >
              Log into my account
            </Link>
          </div>
        </div>
      </SplitPanels>
    </Page>
  );
};

export default React.memo(Home);
